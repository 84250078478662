import { useIonRouter } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ListOption } from "../../../../../entities/commons/list-option.model";
import { getMasterDatas } from "../../../../../utils/apiclient";
import { getUser, setSignOperationInProgress } from "../../../../../utils/storage";
import { closeDialog } from "../../../../../utils/utils";

import { ButtonLayout, ButtonPrimary, ButtonSecondary, Checkbox, dialog, Form, FormValues, GridLayout, IconInformationUserRegular, Inline, Select, skinVars, Stack, Switch, Text2, Text3, TextField, Tooltip } from "@telefonica/mistica";

import './OperationsInProgressCustomerPage.scss';

export const useSignOperationsInProgress= () => {

    const intl = useIntl();
    const user = getUser();
    const [formErrors, setFormErrors] = useState([] as any);
    const [employmentSituationOptions, setEmploymentSituationOptions] = React.useState([] as ListOption[])
    const [activitySectorOptions, setActivitySectorOptions] = React.useState([] as ListOption[])
    const [sourceMoneyOptions, setSourceMoneyOptions] = React.useState([] as ListOption[])
    const [activitySectorOptionsInit, setActivitySectorOptionsInit] = React.useState([] as ListOption[])

    const [formData, setFormData] = useState({
        numSolicitud: "",
        employmentSituation: (user && user.conocimientoCliente && user.conocimientoCliente.ocupacion ? user.conocimientoCliente.ocupacion.charAt(0).toUpperCase() + user.conocimientoCliente.ocupacion.slice(1).toLowerCase() : ""),
        activitySector: user && user.conocimientoCliente && user.conocimientoCliente.actividad ? (user.conocimientoCliente.actividad.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase()) || user.conocimientoCliente.actividad.charAt(0).toUpperCase() + user.conocimientoCliente.actividad.slice(1).toLowerCase()) : "",
        otherActivitySector: user && user.conocimientoCliente && user.conocimientoCliente.actividadEspecificar ? user.conocimientoCliente.actividadEspecificar : "",
        sourceMoney: (user && user.conocimientoCliente && user.conocimientoCliente.procedenciaPatrimonio ? user.conocimientoCliente.procedenciaPatrimonio.charAt(0).toUpperCase() + user.conocimientoCliente.procedenciaPatrimonio.slice(1).toLowerCase() : ""),
        telefonicaGroupEmployee: user && user.conocimientoCliente && user.conocimientoCliente.empleadoGrupoTelefonica ? user.conocimientoCliente.empleadoGrupoTelefonica : false,
        usTaxIdentificationNumber: user && user.conocimientoCliente && user.conocimientoCliente.nifEEUU ? user.conocimientoCliente.nifEEUU : false,
        personPublicResponsibility: user && user.conocimientoCliente && user.conocimientoCliente.personaResponsabilidadPublica ? user.conocimientoCliente.personaResponsabilidadPublica : false,
        personPublicResponsibilityPosition: user && user.conocimientoCliente && user.conocimientoCliente.cargo ? user.conocimientoCliente.cargo : "",
        personPublicResponsibilityOrganism: user && user.conocimientoCliente && user.conocimientoCliente.organismo ? user.conocimientoCliente.organismo : "",
        familyPublicResponsibilityOrganism:  user && user.conocimientoCliente && user.conocimientoCliente.familiarPEP ? user.conocimientoCliente.familiarPEP : false,
        relatedPublicResponsibilityOrganism: user && user.conocimientoCliente && user.conocimientoCliente.allegadoPep ? user.conocimientoCliente.allegadoPep : false,
        ongPositionLast2Years:user && user.conocimientoCliente && user.conocimientoCliente.ongPositionLast2Years ? user.conocimientoCliente.ongPositionLast2Years : false,
        informationTrueVerifiable: false,
        legalOriginIncome: false
      });

    const handleChangeField= (evt:any) => {
        const { target } = evt;
        const { name, value } = target;
        const newValues = {
          ...formData,
          [name]: value,
        };
        setFormData(newValues);
        if(handleError(name)){
          delete formErrors[name];
        }
    };

    const handleChangeAnyField= (name:string, value:any) => {
        const newValues = {
          ...formData,
          [name]: value,
        };
        setFormData(newValues);
        if(handleError(name)){
          delete formErrors[name];
        }
        switch(name){
            case 'employmentSituation':
                switch(value){
                    case 'unemployed':
                    case 'Householder':
                    case 'Retired':
                    case 'Student / Minor':
                    case 'Desempleado':
                    case 'Amo de casa':
                    case 'Jubilado':
                    case 'Estudiante/Menor de edad':
                    const activitySectorOptionsNone = activitySectorOptionsInit.filter(item=>item.valor==='None' || item.valor==='Ninguna');
                    const activitySectorNone = activitySectorOptionsNone.find(item=>item.valor==='None' || item.valor==='Ninguna');
                    setActivitySectorOptions(activitySectorOptionsNone);
                    const newValuesActivitySectorNone = {
                        ...newValues,
                        ['activitySector']: activitySectorNone ? activitySectorNone.valor : ''
                    };
                    setFormData(newValuesActivitySectorNone);
                    break;
                    default:
                    const activitySectorOptions = activitySectorOptionsInit.filter(item=>item.valor!=='None' && item.valor!=='Ninguna');
                    const activitySector = activitySectorOptions.find(item=>item.valor===formData.activitySector);
                    setActivitySectorOptions(activitySectorOptions);
                    const newValuesActivitySector = {
                        ...newValues,
                        ['activitySector']: activitySector ? activitySector.valor : ''
                    };
                    setFormData(newValuesActivitySector);
                    break;
                }
                break;
        }
    };

    const handleSubmit= (values: FormValues, onConfirm:() => void): Promise<void> =>
        new Promise((resolve) => {

            formData.employmentSituation = values.employmentSituation;
            formData.activitySector = values.activitySector;

            if ((formData.activitySector === 'Otros' || formData.activitySector === 'Other' || formData.activitySector === 'ONG' || formData.activitySector === 'NGO')) {
                formData.otherActivitySector = values.otherActivitySector;
            }else{
                formData.otherActivitySector = '';
            }

            formData.sourceMoney = values.sourceMoney;
            formData.telefonicaGroupEmployee = values.telefonicaGroupEmployee;
            formData.usTaxIdentificationNumber = values.usTaxIdentificationNumber;
            formData.personPublicResponsibility = values.personPublicResponsibility;

            if(formData.personPublicResponsibility){
                formData.personPublicResponsibilityPosition = values.personPublicResponsibilityPosition;
                formData.personPublicResponsibilityOrganism = values.personPublicResponsibilityOrganism;
            }else{
                formData.personPublicResponsibilityPosition = '';
                formData.personPublicResponsibilityOrganism = '';
            }


            formData.familyPublicResponsibilityOrganism = values.familyPublicResponsibilityOrganism;
            formData.relatedPublicResponsibilityOrganism = values.relatedPublicResponsibilityOrganism;
            formData.ongPositionLast2Years = values.ongPositionLast2Years;

            setSignOperationInProgress(formData);
            onConfirm();
            resolve();
    });

    const handleError=(name:string) =>{
        if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
            }
        }
        return null;
    }

    useEffect(()=>{
        getMasterDatas('laboralSituation').then(options=>{
            setEmploymentSituationOptions(options);
        });
        getMasterDatas('laboralSectors').then(options=>{
            setActivitySectorOptions(options);
        });
        getMasterDatas('laboralSectors').then(options=>{
            setActivitySectorOptionsInit(options);
        });
        getMasterDatas('sourceMoney').then(options=>{
            setSourceMoneyOptions(options);
        });
      }, []);

	const signOperationInProgress = (numSolicitud:string, onConfirm:() => void) => {

        formData.numSolicitud = numSolicitud;

        if(!formData.personPublicResponsibility){
            const personPublicResponsibilityClass = document.querySelector('.personPublicResponsibility') as HTMLElement;
            const inputPersonPublicResponsibilityPosition = document.querySelector('input[name="personPublicResponsibilityPosition"]') as HTMLInputElement;
            const inputPersonPublicResponsibilityOrganism = document.querySelector('input[name="personPublicResponsibilityOrganism"]') as HTMLInputElement;

            if (personPublicResponsibilityClass) {
                personPublicResponsibilityClass.style.display = 'none';
                if (inputPersonPublicResponsibilityPosition && inputPersonPublicResponsibilityOrganism) {
                    inputPersonPublicResponsibilityPosition.removeAttribute('required');
                    inputPersonPublicResponsibilityOrganism.removeAttribute('required');
                }
            }
        }

        closeDialog().then(()=>{

            const showLoading=()=>{
                const buttonSubmit = document.querySelector('.button-sign-operations-key-submit') as any;
                const buttonLoading = document.querySelector('.button-sign-operations-key-loading') as any;
                document.getElementById('form-sign-operations-progress')?.classList.add('loading');
                if(buttonSubmit){
                    buttonSubmit.style.display = 'none';
                }
                if(buttonLoading){
                    buttonLoading.style.display = 'block';
                }
            }

            const hiddenResponasibilityPublic=()=>{
                const personPublicResponsibilityClass = document.querySelector('.personPublicResponsibility') as HTMLElement;
                const inputPersonPublicResponsibilityPosition = document.querySelector('input[name="personPublicResponsibilityPosition"]') as HTMLInputElement;
                const inputPersonPublicResponsibilityOrganism = document.querySelector('input[name="personPublicResponsibilityOrganism"]') as HTMLInputElement;

                    if (personPublicResponsibilityClass) {
                    const displayValue = getComputedStyle(personPublicResponsibilityClass).getPropertyValue('display');

                    if (displayValue === 'none') {
                        personPublicResponsibilityClass.style.display = '';
                        if (inputPersonPublicResponsibilityPosition && inputPersonPublicResponsibilityOrganism) {
                            inputPersonPublicResponsibilityPosition.setAttribute('required', 'required');
                            inputPersonPublicResponsibilityOrganism.setAttribute('required', 'required');
                        }
                    }else {
                        personPublicResponsibilityClass.style.display = 'none';
                        if (inputPersonPublicResponsibilityPosition && inputPersonPublicResponsibilityOrganism) {
                            inputPersonPublicResponsibilityPosition.removeAttribute('required');
                            inputPersonPublicResponsibilityOrganism.removeAttribute('required');
                        }
                    }
                }
            }

            const controlOtherActivity=(val:any)=>{

                handleChangeAnyField('activitySector', val);

                const otherActivitySectorClass = document.querySelector('.otherActivitySectorField') as HTMLElement;
                const inputOtherActivitySector = document.querySelector('input[name="otherActivitySector"]') as HTMLInputElement;

                switch ((val || '').toLowerCase()) {
                    case 'otros':
                    case 'other':
                    case 'ong':
                    case 'ngo':
                        otherActivitySectorClass.style.display = '';
                        otherActivitySectorClass.style.visibility = '';
                        inputOtherActivitySector.setAttribute('required', 'required');
                        break;
                    default:
                        otherActivitySectorClass.style.display = 'none';
                        otherActivitySectorClass.style.visibility = 'hidden';
                        inputOtherActivitySector.removeAttribute('required');
                        break;
                }
            }

            const controlCheck=(nameCheck: string, value: boolean)=>{
                const informationTrueVerifiable = nameCheck === 'informationTrueVerifiable' ? value : document.getElementById('informationTrueVerifiable')?.getAttribute('aria-checked') === 'true';
                const legalOriginIncome = nameCheck === 'legalOriginIncome' ? value : document.getElementById('legalOriginIncome')?.getAttribute('aria-checked') === 'true';

                if (informationTrueVerifiable && legalOriginIncome) {
                    const elements = document.querySelectorAll('.button-sign-operations-key-submit');
                    elements.forEach(element => {
                        element.removeAttribute('disabled');
                    });
                } else {
                    const elements = document.querySelectorAll('.button-sign-operations-key-submit');
                    elements.forEach(element => {
                        element.setAttribute('disabled', 'disabled');
                    });
                }
            }

            dialog({
                title: intl.formatMessage({id:'sign_operations_key_customer_knowledge'}),
                message:intl.formatMessage({id:'sign_operations_key_customer_knowledge_description'}),
                extra: <>
                        <Form id="form-sign-operations-progress" className="scrollable-container" initialValues={formData} onSubmit={(data)=>{showLoading(); handleSubmit(data, onConfirm)}}>
                            <Stack className="scrollable-content" space={32}>
                                <GridLayout verticalSpace={16} template='6+6'
                                    left={
                                        <Select fullWidth label={intl.formatMessage({id:'page_register_employment_situation'})} name="employmentSituation" id="employmentSituation" error={handleError('employmentSituation')!==null} helperText={handleError('employmentSituation')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('employmentSituation', val)} options={employmentSituationOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                    }
                                    right={
                                        <Stack space={16}>
                                            <Select fullWidth label={intl.formatMessage({id:'page_register_activity_sector'})} name="activitySector" id="activitySector" error={handleError('activitySector')!==null} helperText={handleError('activitySector')??undefined} disabled={activitySectorOptions.length===0}  onChangeValue={(val:any)=> controlOtherActivity(val)} options={activitySectorOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                            <div className="otherActivitySectorField" style={['otros', 'other', 'ong', 'ngo'].includes((formData.activitySector || '').toLowerCase()) ? {} : { display: 'none' }}>
                                                <TextField fullWidth name="otherActivitySector" id="otherActivitySector" error={handleError('otherActivitySector')!==null} helperText={handleError('otherActivitySector')??undefined}  label={intl.formatMessage({id:'page_register_other_activity_sector'})} onChange={handleChangeField} />
                                            </div>
                                        </Stack>
                                }/>
                                <GridLayout verticalSpace={16} template='6+6'
                                    left={
                                        <Select fullWidth label={intl.formatMessage({id:'page_register_source_money'})} name="sourceMoney" error={handleError('sourceMoney')!==null} helperText={handleError('sourceMoney')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('sourceMoney', val)} options={sourceMoneyOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                    }
                                    right={
                                        <></>
                                }/>
                                <Stack space={16}>
                                    <Switch onChange={(val:any)=> handleChangeAnyField('telefonicaGroupEmployee', val)} name="telefonicaGroupEmployee"  render={({controlElement}) => (
                                    <Inline alignItems="center" space="between">
                                        <Text2 medium >
                                        {intl.formatMessage({id:'page_register_telefonica_group_employee'})}
                                        </Text2>
                                        {controlElement}
                                    </Inline>
                                    )}></Switch>

                                    <Switch onChange={(val:any)=> handleChangeAnyField('usTaxIdentificationNumber', val)} name="usTaxIdentificationNumber" render={({controlElement}) => (
                                    <Inline alignItems="center" space="between">
                                        <Text2 medium >
                                        {intl.formatMessage({id:'page_register_us_tax_identification_number'})}
                                        </Text2>
                                        {controlElement}
                                    </Inline>
                                    )}></Switch>

                                    <Switch onChange={(val:any)=> {handleChangeAnyField('personPublicResponsibility', val); hiddenResponasibilityPublic(); } } name="personPublicResponsibility" render={({controlElement}) => (
                                    <Inline alignItems="center" space="between">
                                        <Inline alignItems="center" space={8}>
                                        <Text2 medium>
                                            {intl.formatMessage({id:'page_register_person_public_responsibility'})}
                                        </Text2>
                                        <Tooltip
                                            targetLabel="help text"
                                            target={<IconInformationUserRegular  color={skinVars.colors.brand}/>}
                                            position="top"
                                            description={intl.formatMessage({id:'page_register_person_public_responsibility_help_text'})}
                                        />
                                        </Inline>
                                        {controlElement}
                                    </Inline>
                                    )}></Switch>

                                    <div className="personPublicResponsibility" style={!formData.personPublicResponsibility ? { display: 'none' } : {}}>
                                        <GridLayout verticalSpace={16} template='6+6'
                                            left={
                                            <TextField
                                                fullWidth
                                                name="personPublicResponsibilityPosition"
                                                error={handleError('personPublicResponsibilityPosition') !== null}
                                                helperText={handleError('personPublicResponsibilityPosition') ?? undefined}
                                                label={intl.formatMessage({ id: 'page_register_person_public_responsibility_position' })}
                                                onChange={handleChangeField}
                                            />
                                            }
                                            right={
                                            <TextField
                                                fullWidth
                                                name="personPublicResponsibilityOrganism"
                                                error={handleError('personPublicResponsibilityOrganism') !== null}
                                                helperText={handleError('personPublicResponsibilityOrganism') ?? undefined}
                                                label={intl.formatMessage({ id: 'page_register_person_public_responsibility_organism' })}
                                                onChange={handleChangeField}
                                            />
                                            }
                                        />
                                    </div>

                                    <Switch onChange={(val:any)=> handleChangeAnyField('familyPublicResponsibilityOrganism', val)} name="familyPublicResponsibilityOrganism" render={({controlElement}) => (
                                    <Inline alignItems="center" space="between">
                                        <Inline alignItems="center" space={8}>
                                        <Text2 medium >
                                            {intl.formatMessage({id:'page_register_family_public_responsibility_organism'})}
                                        </Text2>
                                        <Tooltip
                                            targetLabel="help text"
                                            target={<IconInformationUserRegular color={skinVars.colors.brand}/>}
                                            position="top"
                                            description={intl.formatMessage({id:'page_register_family_public_responsibility_organism_help_text'})}
                                        />
                                        </Inline>
                                        {controlElement}
                                    </Inline>
                                    )}></Switch>

                                    <Switch onChange={(val:any)=> handleChangeAnyField('relatedPublicResponsibilityOrganism', val)} name="relatedPublicResponsibilityOrganism" render={({controlElement}) => (
                                    <Inline alignItems="center" space="between">
                                        <Inline alignItems="center" space={8}>
                                        <Text2 medium >
                                            {intl.formatMessage({id:'page_register_related_public_responsibility_organism'})}
                                        </Text2>
                                        <Tooltip
                                            targetLabel="help text"
                                            target={<IconInformationUserRegular color={skinVars.colors.brand}/>}
                                            position="top"
                                            description={intl.formatMessage({id:'page_register_related_public_responsibility_organism_help_text'})}
                                        />
                                        </Inline>
                                        {controlElement}
                                    </Inline>
                                    )}></Switch>

                                    <Switch onChange={(val:any)=> handleChangeAnyField('ongPositionLast2Years', val)} name="ongPositionLast2Years" render={({controlElement}) => (
                                    <Inline alignItems="center" space="between">
                                        <Inline alignItems="center" space={8}>
                                        <Text2 medium >
                                            {intl.formatMessage({id:'page_register_question_ong'})}
                                        </Text2>
                                        </Inline>
                                        {controlElement}
                                    </Inline>
                                    )}></Switch>
                                </Stack>
                                <Stack space={16}>
                                <Checkbox id="informationTrueVerifiable" onChange={(val) => controlCheck('informationTrueVerifiable', val)} name="informationTrueVerifiable" render={({controlElement, labelId}:any) => (
                                    <Inline alignItems="center" space={16}>
                                    {controlElement}
                                    <Text3 regular id={labelId}>
                                        {intl.formatMessage({id:'page_register_information_true_verifiable'})}
                                    </Text3>
                                    </Inline>
                                )}></Checkbox>

                                <Checkbox id="legalOriginIncome" onChange={(val) => controlCheck('legalOriginIncome', val)} name="legalOriginIncome" render={({controlElement, labelId}:any) => (
                                    <Inline alignItems="center" space={16}>
                                    {controlElement}
                                    <Text3 regular id={labelId}>
                                        {intl.formatMessage({id:'page_register_legal_origin_income'})}
                                    </Text3>
                                    </Inline>
                                )}></Checkbox>
                                </Stack>
                                <ButtonLayout align='full-width'>
                                    <ButtonSecondary onPress={()=>{closeDialog()}}>
                                        {intl.formatMessage({id:'sign_operations_key_action_cancel'})}
                                    </ButtonSecondary>
                                    <>
                                        <ButtonPrimary className="button-sign-operations-key-submit" disabled submit>
                                            {intl.formatMessage({id:'page_operations_in_progress_sign_operation_button'})}
                                        </ButtonPrimary>
                                        <ButtonPrimary className="button-sign-operations-key-loading" onPress={()=>{}} showSpinner={true} style={{display:'none'}}>
                                            {intl.formatMessage({id:'page_operations_in_progress_sign_operation_button'})}
                                        </ButtonPrimary>
                                    </>
                                </ButtonLayout>
                            </Stack>
                        </Form>
                    </>,
                className:'dialog-without-buttons'
            });
        });
        return (<></>);
	}
	return { signOperationInProgress };
};